<template>
    <el-container>
        <el-main :model="form">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules">
                <div class="item">
                    <el-form-item label="优惠券名称：" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <span class="sp">名称不超过15个字，中文</span>
                </div>
                <div class="item">
                    <el-form-item label="优惠券类型：" prop="type">
                        <el-radio-group v-model="form.type">
                            <el-radio :label="1">满减券</el-radio>
                            <el-radio :label="2">立减券</el-radio>
                            <el-radio :label="3">折扣券</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="item Full-reduction" v-show="form.type == 1">
                    <span style="width: 120px"></span>
                    <div class="reduction-box">
                        <span style="margin-right: 10px">消费满</span>
                        <el-input type="number" :min="0" v-model="form.consume_full">
                            <template slot="append">元</template>
                        </el-input>
                        <span style="margin: 0 10px">减</span>
                        <el-input type="number" :min="0" v-model="form.subtract">
                            <template slot="append">元</template>
                        </el-input>
                        <span style="margin-left: 10px">运费除外</span>
                    </div>
                </div>
                <div class="item Full-reduction" v-show="form.type == 3">
                    <span style="width: 120px"></span>
                    <div class="reduction-box">
                        <span style="margin-right: 10px">消费满</span>
                        <el-input type="number" :min="0" v-model="form.consume_full">
                            <template slot="append">元</template>
                        </el-input>
                        <span style="margin: 0 10px">折扣</span>
                        <el-input type="number" :min="0" v-model="form.discount">
                            <template slot="append">折</template>
                        </el-input>
                    </div>
                </div>
                <div class="item Full-reduction" v-show="form.type == 2">
                    <span style="width: 120px"></span>
                    <div class="reduction-box">
                        <span style="margin-right: 10px">无门槛，消费立减</span>
                        <el-input type="number" :min="0" style="width: 460px" v-model="form.subtract">
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                </div>
                <div class="item">
                    <el-form-item label="领券时间：" prop="activity_start_time">
                        <el-date-picker v-model="receiveTime" type="daterange" range-separator="~" @change="getReceiveTime"
                            start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="item effective">
                    <el-form-item label="优惠券有效期：" prop="time_type">
                        <el-radio-group v-model="form.time_type">
                            <el-radio :label="1">固定时间</el-radio>
                            <el-radio :label="2">领取后N天内</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="form.time_type == 1" label="" prop="start_time">
                        <el-date-picker v-model="effectiveTimeVal" type="daterange" range-separator="~"
                            @change="getEffectiveTimeVal" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item v-show="form.time_type == 2" label="" prop="days">
                        <el-input v-model="form.days">
                            <template slot="append">天内</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="发放数量：" prop="number">
                        <el-input type="number" :min="0" v-model="form.number">
                            <template slot="append">张</template>
                        </el-input>
                    </el-form-item>
                </div>
                <!--<div class="item">
          <el-form-item label="用户资格：">
            <el-select v-model="form.level_id" style="width: 100%" placeholder="请选择">
              <el-option v-for="item in userGrade" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>-->
                <div class="item">
                    <el-form-item label="每人限领：" prop="each_limit">
                        <el-input type="number" :min="0" v-model="form.each_limit">
                            <template slot="append">张</template>
                        </el-input>
                    </el-form-item>
                </div>
                <!--<div class="item store">
                    <el-form-item label="关联店铺：">
                        <el-tag color="#fff" :key="tag.id" v-for="tag in store" closable :disable-transitions="false"
                            @close="removeStore(tag)">
                            {{ tag.store_name }}
                        </el-tag>
                        <p @click="showAddStore = !0">+设置关联店铺</p>
                    </el-form-item>
                </div>-->
                <div class="item">
                    <el-form-item label="状态：">
                        <el-radio-group v-model="form.status">
                            <el-radio :label="1">正常</el-radio>
                            <el-radio :label="0">停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <!-- <div class="item">
          <el-form-item label="">
            <el-button type="primary" @click="submit">保存</el-button>
          </el-form-item>
        </div> -->
                <Preservation @preservation="submit"></Preservation>
            </el-form>
        </el-main>
        <AddStroe @getStore="getStore" @closeAddStore="closeAddStore" :is_show="showAddStore">
            <router-link to="/extension/couponList" slot="return" v-if="form.id" style="margin-right: 10px">
                <el-button>返回</el-button>
            </router-link>
        </AddStroe>
    </el-container>
</template>

<script>
import AddStroe from '@/views/goods/components/AddStore';
import Preservation from '@/components/preservation';
export default {
    components: {
        AddStroe,
        Preservation,
    },
    data () {
        return {
            showAddStore: !1,
            effectiveTimeVal: '',
            receiveTime: '',
            store: [],
            //  userGrade: [],
            form: {
                name: '', //名称
                type: 1, //优惠券类型，1满减券，2立减券，3满折券
                consume_full: '', //消费满
                subtract: '', //立减
                discount: '', //	折扣
                activity_start_time: '', //活动开始时间
                activity_end_time: '', //活动结束时间
                time_type: 1, //有效期类型，1固定时间，2领取后N天内
                start_time: '', //有效开始时间
                end_time: '', //	有效结束时间
                days: '', //有效天数
                //level_id: 0, //会员级别ID
                number: '', //发放数量
                each_limit: '', //每人限领
                status: 1, //状态，0停用，1正常
                store_id: [], //关联门店id数组([1,2])，不关联空
            },
            rules: {
                name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
                activity_start_time: [{ required: true, message: '请填写领券时间', trigger: 'blur' }],
                start_time: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
                time_type: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
                days: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
                number: [{ required: true, message: '请填写发放数量', trigger: 'blur' }],
                each_limit: [{ required: true, message: '请填写限领数量', trigger: 'blur' }],
                type: [{ required: true, message: '', trigger: 'blur' }],
            },
        };
    },
    created () {
        //this.$axios.post(this.$api.user.CardLevel).then((res) => {
        //  if (res.code == 0) {
        //    this.userGrade = res.result.list;
        //    this.userGrade.unshift({
        //      id: 0,
        //      title: '所有用户',
        //    });
        //  }
        //});
        let couponInfo = this.$route.query.info;
        if (couponInfo) {
            couponInfo = JSON.parse(couponInfo);
            this.couponInfo(couponInfo);
        }
    },
    methods: {
        //处理编辑信息
        couponInfo (couponInfo) {
            let store_id = [];
            for (let i in couponInfo.store_id) store_id.push(couponInfo.store_id[i].id);
            this.effectiveTimeVal = [new Date(couponInfo.start_time * 1000), new Date(couponInfo.end_time * 1000)];
            this.receiveTime = [new Date(couponInfo.activity_start_time * 1000), new Date(couponInfo.activity_end_time * 1000)];
            this.store = couponInfo.store_id;
            this.form = couponInfo;
            this.form.store_id = [];
        },
        getEffectiveTimeVal (val) {
            this.form.start_time = val[0].getTime() / 1000;
            this.form.end_time = val[1].getTime() / 1000;
        },
        getReceiveTime (val) {
            this.form.activity_start_time = val[0].getTime() / 1000;
            this.form.activity_end_time = val[1].getTime() / 1000;
        },
        //获取门店
        getStore (val) {
            this.showAddStore = !1;
            this.store = val;
        },
        removeStore (tag) {
            this.store.splice(this.store.indexOf(tag), 1);
        },
        closeAddStore () {
            this.showAddStore = !1;
        },
        submit () {
            try {
                let form = this.form;
                if (!form.name) throw '请填写优惠券名称';
                if (form.type == 1) {
                    if (!form.consume_full) throw '请填写消费满多少';
                    if (!form.subtract) throw '请填写立减多少';
                } else if (form.type == 2) {
                    if (!form.subtract) throw '请填写立减多少';
                } else {
                    if (!form.consume_full) throw '请填写消费满多少';
                    if (!form.discount) throw '请填写折扣多少';
                }
                if (!this.receiveTime) throw '请填写领券时间';
                if (form.time_type == 1) {
                    if (!this.effectiveTimeVal) throw '请填写优惠券有效期';
                } else {
                    if (!form.days) throw '请填写优惠券有效期';
                }
                if (!form.number) throw '请填写发放数量';
                if (!form.each_limit) throw '请填写限领数量';
                //if (this.store.length) {
                //    for (let i in this.store) form.store_id.push(this.store[i].id);
                //}
                let url = form.id ? this.$api.push.couponEdit : this.$api.push.addCoupon;
                this.$axios.post(url, form).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: form.id ? '编辑成功' : '添加成功',
                            type: 'success',
                        });
                        this.$router.push('/extension/couponList');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } catch (e) {
                this.$message({
                    message: e,
                    type: 'warning',
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-container {
    height: 100%;

    .el-main {
        background: #fff;
        padding: 50px 70px;
    }

    .el-form {
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .sp {
                font-size: 14px;
                margin-left: 10px;
            }
        }

        .Full-reduction {
            .reduction-box {
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                }

                .el-input {
                    width: 247px;
                }
            }
        }

        .store {
            .el-form-item {
                align-items: center;
            }

            .el-tag {
                margin-right: 10px;
            }

            p {
                cursor: pointer;
                color: #409eff;
            }
        }

        .effective {
            flex-direction: column;
            align-items: flex-start;
        }

        .el-form-item {
            width: 700px;
            margin: 0;
        }
    }
}
</style>
